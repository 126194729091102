import api from "@/core/api/api";

export const info = (params: any) => api.query(`/pgi/info`, { data: params, method: "post" });
export const setStatus = (id: string, params: any) => api.query(`/pgi/setstatus/${id}`, { data: params, method: "post" }, false);
export const getResearch = (researchs: string) => api.query(`/pgi/getresearch`, { data: researchs, method: "post" });

export const researchComplete = (id: string) => api.query(`/pgi/researchcomplete/${id}`, { method: "post" });

export const lastBiopsyNmb = (data: any) => api.query(`/pgi/lastbiopsynmb`, { data, method: "post" }, false);
export const checkBeforeCreating = (data: any) => api.query(`/pgi/checkbeforecreating`, { data, method: "post" });

export const registered = (data: any) => api.query(`/pgi/registered`, { data, method: "post" });
export const toConclusion = (data: any) => api.query(`/pgi/toconclusion`, { data, method: "post" });

export const consultationOKO = (data: any) => api.query(`/pgi/consultationoko`, { data, method: "post" });
export const consultationRNPC = (data: any) => api.query(`/pgi/consultationrnpc`, { data, method: "post" });

export const getNumeratorData = (data: any) => api.query(`/pgi/getnumeratordata`, { data, method: "post" });

export const researchesMailing = (researches: any) => api.query(`/pgi/researchesmailing`, { data: researches, method: "post" });

export const exportTable = (params: any = {}) => api.query(`/pgi/export`, { data: params, method: "post", responseType: 'blob' });


export const consultationCompleteOKO = (data: any) => api.query(`/pgi/consultationcompleteoko`, { data, method: "post" });
export const revisionCompleteOKO = (data: any) => api.query(`/pgi/revisioncompleteoko`, { data, method: "post" });
export const completeRNPC = (data: any) => api.query(`/pgi/completernpc`, { data, method: "post" });
export const confirmOKO = (data: any) => api.query(`/pgi/confirmoko`, { data, method: "post" });
export const cancelRevision = (data: any) => api.query(`/pgi/cancelrevision`, { data, method: "post" });
