export default {
	forms: {
		list: {
			config: {
				fields: {
					id: {
						description: 'id',
						config: {
							hide: true
						}
					},
					name: {
						description: 'Наименование'
					},
					prefix: {
						description: 'Префикс'
					},
					use_prefix: {
						description: "Использовать префикс в нумерации"
					},
					use_new_nmb: {
						description: "Использовать новую нумерацию"
					},
					use_pay_nmb: {
						description: "Для платных своя нумерация"
					}
				}
			}
		},
		select: {
			config: {
				fields: {
					id: {
						description: 'id',
						config: {
							hide: true
						}
					},
					name: {
						description: 'Наименование'
					},
					prefix: {
						description: 'Префикс'
					},
					use_prefix: {
						description: "Использовать префикс в нумерации"
					},
					use_new_nmb: {
						description: "Использовать новую нумерацию"
					},
					use_pay_nmb: {
						description: "Для платных своя нумерация"
					}
				}
			}
		},
		edit: {
			config: {
				fields: {
					id: {
						description: 'id',
						config: {
							hide: true
						}
					},
					name: {
						description: 'Наименование'
					},
					prefix: {
						description: 'Префикс'
					},
					use_prefix: {
						description: "Использовать префикс в нумерации"
					},
					use_new_nmb: {
						description: "Использовать новую нумерацию"
					},
					use_pay_nmb: {
						description: "Для платных своя нумерация"
					}
				}
			}
		}
	}
}