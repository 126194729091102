import access from "./access";
import accounts from "./accounts";
import address_districts from "./address_districts";
import address_locality from "./address_locality";
import address_street from "./address_street";
import autopsy_research from "./autopsy_research";
import banks from "./banks";
import coato from "./coato";
import connected_equipment from "./connected_equipment";
import contacts from "./contacts";
import contacts_types from "./contacts_types";
import contragents from "./contragents";
import contragents_departments from "./contragents_departments";
import contragents_performers from "./contragents_performers";
import country from "./country";
import death_certificate from "./death_certificate";
import divisions_mvd from "./divisions_mvd";
import document_types from "./document_types";
import employees from "./employees";
import external_biopsy from "./external_biopsy";
import files from "./files";
import files_versions from "./files_versions";
import fizlica from "./fizlica";
import history_changes from "./history_changes";
import icd10 from "./icd10";
import icdo from "./icdo";
import igh_antibody from "./igh_antibody";
import mailing from "./mailing";
import messages from "./messages";
import menu from "./menu";
import nomenclature from "./nomenclature";
import numerators from "./numerators";
import numerator from "./numerator";
import autopsy_report from "./autopsy_report";
import paid_services from "./paid_services";
import patients from "./patients";
import pgi_research from "./pgi_research";
import positions from "./positions";
import publications from "./publications";
import rates from "./rates";
import receiving_method from "./receiving_method";
import reg_bodies from "./reg_bodies";
import roles from "./roles";
import templates from "./templates";
import types_material from "./types_material";
import units from "./units";
import users from "./users";
import users_roles from "./users_roles";
import neoplasm_notice from "./neoplasm_notice";
import often_used from "./often_used";
import bak_direction from "./bak_direction";
import pgi from "./pgi";


export default {
	access,
	accounts,
	address_districts,
	address_locality,
	address_street,
	autopsy_research,
	banks,
	coato,
	connected_equipment,
	contacts,
	contacts_types,
	contragents,
	'contragents.departments': contragents_departments,
	'contragents.performers': contragents_performers,
	country,
	death_certificate,
	divisions_mvd,
	document_types,
	employees,
	external_biopsy,
	files,
	files_versions,
	fizlica,
	history_changes,
	icd10,
	icdo,
	igh_antibody,
	mailing,
	messages,
	menu,
	nomenclature,
	numerators,
	numerator,
	paid_services,
	patients,
	pgi_research,
	positions,
	publications,
	rates,
	receiving_method,
	reg_bodies,
	roles,
	templates,
	types_material,
	units,
	autopsy_report,
	users,
	'users.roles': users_roles,
	bak_direction,
	neoplasm_notice,
	often_used,
	pgi
}
